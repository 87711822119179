import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import ShareButton from "../../../components/share-button"
import BtnCircle from "../../../components/button-circle"
import GalleryCategoryTilesMobile from "../../../components/gallery-category-tiles-mobile"
import GalleryCategoryTiles from "../../../components/gallery-category-tiles"
import BtnLeft from "../../../components/button-left"

const GaleriaZimaIndex = ({ location }) => {
  const pageData = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/zima/" }) {
        frontmatter {
          title
        }
        html
      }
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(blog)/" }
          frontmatter: { inGallery: { eq: true }, tags: { in: ["zima"] } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            date(formatString: "YYYY-MM-DD")
            title
            description
          }
        }
      }
    }
  `)
  const posts = pageData.allMarkdownRemark.nodes
  const pageTitle = pageData.markdownRemark.frontmatter.title
  const pageBody = pageData.markdownRemark.html
  const featuredImage = getImage(
    pageData.markdownRemark.frontmatter.featuredImage
  )

  return (
    <Layout location={location} title={pageTitle}>
      <div className="section">
        <SEO title="Galeria obrazów" />
        <div className="columns">
          <div className="column is-8-desktop is-12-tablet content-container has-background-dark is-6">
            <div className="columns is-mobile">
              <div className="column has-text-left ml-3">
                <BtnLeft
                  className=""
                  path={"/galeria"}
                  description={"DO GALERII"}
                />
              </div>
              <div className="column has-text-right">
                <ShareButton location={location} />
              </div>
            </div>
            <section className="section has-text-justified has-text-weight-light is-size-5 pb-4 pt-3">
              {/* <div
                className="md-has-dropcap md-article"
                dangerouslySetInnerHTML={{ __html: pageBody }}
              /> */}
              {/* <p className="subtitle is-6 has-text-left is-family-secondary pt-6 mb-4 has-text-weight-light has-letter-spacing-1"> */}
              <h2 className="title is-3 is-family-secondary has-text-weight-light has-letter-spacing-1 is-title-underlined is-relative">
                ZIMA
              </h2>

              <div className="columns is-multiline">
                {posts.map(post => {
                  const title = post.frontmatter.title || post.fields.slug
                  const featuredImage = getImage(post.frontmatter.featuredImage)

                  return (
                    <div
                      className="column is-one-third mb-6 mt-6 gallery-column"
                      key={post.fields.slug}
                    >
                      <section className="has-text-centered">
                        <Link to={post.fields.slug} itemProp="url">
                          <GatsbyImage
                            objectFit="cover"
                            objectPosition="center center"
                            image={featuredImage}
                            alt={`Obraz - tytuł here`}
                            className="gallery-column"
                          />

                          <span
                            className="subtitle is-size-7 is-uppercase has-text-weight-light is-gold-link has-letter-spacing-1"
                            itemProp="headline"
                          >
                            {title}
                          </span>
                        </Link>
                      </section>
                    </div>
                  )
                })}
              </div>
              <div className="mt-6">
                <BtnCircle
                  path={"/galeria/zima"}
                  description={"ZOBACZ INNE OBRAZY Z TEGO DZIAŁU"}
                />
              </div>
            </section>
            <section className="is-hidden-desktop">
              <GalleryCategoryTilesMobile />
            </section>
          </div>
          <div className="column is-offset-1 is-3 is-1-tablet is-hidden-touch">
            <GalleryCategoryTiles />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GaleriaZimaIndex
